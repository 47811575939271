import makeStyles from '@material-ui/styles/makeStyles/makeStyles';
import React, { useEffect, useRef, useState } from 'react';
import { AdFormat, IBoard, IFeedTile, ILayout } from '../../../api/boards';
import { ITheme } from '../../../theme/type';
import { useLocation, useParams } from 'react-router';

import { adShown } from '../../../api/news';
import MainView, { IMainTileStyle } from './components/MainViewItem';

import NewsItem from './components/NewsItem';
import clsx from 'clsx';


const ROTATION_PERIOD = 360000; // 8 min
const ROTATION_SLOTS = 24;
const AD_SCREEN_TIME = 15000;  // 15 sec

export const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    background: theme.palette.background.default
  },
  title: {
    ...theme.typography.heading,
    padding: '54px 70px 40px',
    '&.full-list': {
      paddingTop: 66
    }
  },
  wrapper: {
    position: 'relative',
    height: 1680
  },
  slides: {
    position: 'absolute',
    left: 0,
    top: 0,
    transition: 'left 300ms ease-out 0s',
    display: 'flex'
  },
  points: {
    position: 'absolute',
    left: 90,
    bottom: 80,
    height: 16,
    display: 'flex'
  },
  point: {
    width: 16,
    height: 16,
    borderRadius: 8,
    background: 'rgba(256,256,256,0.3)',
    marginRight: 26,
    transition: 'background 300ms ease 0s',
    zIndex: 1000,
    '&.active': {
      background: '#fff'
    }
  },
  list: {
    padding: '0 10px',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 700px)',
    gridTemplateRows: '580px 580px',
    gridGap: '20px',
    '&.FEED_1X1': {
      gridTemplateAreas: `
      "feed_1x1-0 feed_1x1-1 feed_1x1-2"
      "feed_1x1-3 feed_1x1-4 feed_1x1-5"
      `
    },
    '&.FEED_1X2-1': {
      gridTemplateAreas: `
      "feed_1x2-0 feed_1x1-1 feed_1x1-2"
      "feed_1x2-0 feed_1x1-3 feed_1x1-4"
      `
    },
    '&.FEED_1X2-2': {
      gridTemplateAreas: `
      "feed_1x2-0 feed_1x2-1 feed_1x1-2"
      "feed_1x2-0 feed_1x2-1 feed_1x1-3"
      `
    },
    '&.FEED_1X2-3': {
      gridTemplateAreas: `
      "feed_1x2-0 feed_1x2-1 feed_1x2-2"
      "feed_1x2-0 feed_1x2-1 feed_1x2-2"
      `
    },
    '&.FEED_2X2-0': {
      gridTemplateAreas: `
      "feed_2x2-0 feed_2x2-0 feed_1x1-1"
      "feed_2x2-0 feed_2x2-0 feed_1x1-2"
      `
    },
    '&.FEED_2X2-1': {
      gridTemplateAreas: `
      "feed_1x1-0 feed_2x2-1 feed_2x2-1"
      "feed_1x1-2 feed_2x2-1 feed_2x2-1"
      `
    },
    '&.FEED_2X2-1X2-0': {
      gridTemplateAreas: `
      "feed_2x2-0 feed_2x2-0 feed_1x2-1"
      "feed_2x2-0 feed_2x2-0 feed_1x2-1"
      `
    },
    '&.FEED_2X2-1X2-1': {
      gridTemplateAreas: `
      "feed_1x2-0 feed_2x2-1 feed_2x2-1"
      "feed_1x2-0 feed_2x2-1 feed_2x2-1"
      `
    },
    '&.FEED_3X2': {
      gridTemplateAreas: `
      "feed_3x2-0 feed_3x2-0 feed_3x2-0"
      "feed_3x2-0 feed_3x2-0 feed_3x2-0"
      `
    }
  }
}));

interface IProps {
  getLayout: any;
  layout: ILayout;
  uuid: string;
  board: IBoard;
}

export function getTileStyle(index: number, active: number, isAd: boolean, picInPic: boolean, isHalf: boolean): IMainTileStyle {
  if (picInPic) {
    if (isAd) {
      return {
        opacity: index === active ? 1 : 0,
        zIndex: index === active ? 100 : 0,
        width: (2160 / 2 * 0.9) + 5,
        height: (3050 / (isHalf ? 4 : 2) * 0.9) + 5,
        left: 2160 - (2160 / 2 * 0.9) - 5,
        borderLeft: '5px solid #fff',
        borderBottom: '5px solid #fff',
        transition: 'none'
      }
    } else {
      return {
        opacity: (picInPic && index === active - 1) ? 1 : 0,
        zIndex: (picInPic && index === active - 1) ? 90 : 0,
        transition: 'opacity 300ms ease 0s'
      }
    }
  }
  return {
    opacity: index === active ? 1 : 0,
    zIndex: index === active ? 100 : 0,
    transition: 'opacity 300ms easy 0s'
  }
}

export default function MainNews({ layout, getLayout, uuid, board }: IProps) {
  const classes = useStyles();
  const [activeFeed, setActiveFeed] = useState<number | undefined>();
  const [activeMain, setActiveMain] = useState<number | undefined>();
  const [fullView, setFullView] = useState(false);
  const [picInPic, setPicInPic] = useState(false);
  const [nextTimeoutAt, setNextTimeoutAt] = useState(0);
  const rotateTimeout = useRef<any>(null);

  const { boardId } = useParams();
  const { search } = useLocation();

  const isTracking = search.includes('track=1');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setNextTimeout = (current: number, feedCurrent: number, isFullView: boolean, isPicInPic: boolean, fireAfter?: number) => {
    console.info("MainNews.setNextTimeout enter")
    console.info("MainNews.setNextTimeout current =", current)
    console.info("MainNews.setNextTimeout feedCurrent =", feedCurrent)
    console.info("MainNews.setNextTimeout fireAfter =", fireAfter)
    console.info("MainNews.setNextTimeout isPicInPic =", isPicInPic)
    console.info("MainNews.setNextTimeout isFullView =", isFullView)
    console.info("MainNews.setNextTimeout rotateTimeout =", JSON.stringify(rotateTimeout))
    console.debug("MainNews.setNextTimeout layout.main[current] =", JSON.stringify(layout.main[current]))
    console.debug("MainNews.setNextTimeout layout.main =", JSON.stringify(layout.main))
    console.debug("MainNews.setNextTimeout layout.feed =", JSON.stringify(layout.feed))

    clearTimeout(rotateTimeout.current);
    const tile = layout.main[current];
    console.debug("MainNews.setNextTimeout tile =", JSON.stringify(tile))

    if (tile) {
      const seconds = tile.showDuration.replace('S', '');
      const next = layout.main[current + 1] ? current + 1 : 0;
      console.info("MainNews.setNextTimeout tile seconds =", seconds)
      console.info("MainNews.setNextTimeout tile current =", current)
      console.info("MainNews.setNextTimeout tile next =", next)
      console.debug("MainNews.setNextTimeout tile layout.main[current + 1] =", JSON.stringify(layout.main[current + 1]))

      const rotate = () => {
        console.info("MainNews.setNextTimeout.rotate enter")

        console.info("MainNews.setNextTimeout.rotate feedCurrent =", feedCurrent)
        console.info("MainNews.setNextTimeout.rotate next =", next)
        console.debug("MainNews.setNextTimeout.rotate layout.main =", JSON.stringify(layout.main))
        console.debug("MainNews.setNextTimeout.rotate layout.feed =", JSON.stringify(layout.feed))

        console.info("MainNews.setNextTimeout.rotate before setActiveMain")
        // here is set for new timeout for rotate function
        setActiveMain(next);
        console.info("MainNews.setNextTimeout.rotate after setActiveMain")

        if (isFullView && !isPicInPic) {
          console.info("MainNews.setNextTimeout.rotate setPicInPic = true")
          setPicInPic(true);
        } else if (isFullView && isPicInPic) {
          console.info("MainNews.setNextTimeout.rotate setPicInPic = false, setFullView = false")
          setPicInPic(false);
          setFullView(false);
        }
        if (!layout.main[next].adId) {
          console.info("MainNews.setNextTimeout.rotate setActiveFeed feedCurrent =", feedCurrent)
          setActiveFeed(layout.feed[feedCurrent + 1] ? feedCurrent + 1 : 0);
        }

        console.info("MainNews.setNextTimeout.rotate login done, tracking left")

        if (isTracking && !!layout.main[next].adId) {
          const adId = layout.main[next].adId;

          // В зависимости от версии киоска отправляем аналитику
          // @ts-ignore
          if (window.adShownInElectron && board.version > 1) {
            console.info('MainNews.setNextTimeout.rotate adShownInElectron start (electron)');
            // @ts-ignore
            window.adShownInElectron({
              adId,
              boardId: Number(boardId),
              utcTimestamp: new Date(new Date().toUTCString()).getTime()
            });
            console.debug('MainNews.setNextTimeout.rotate ad logged to server (electron)', JSON.stringify(layout.main[next]));
          } else {
            adShown({ adId, boardId: Number(boardId) }).then(r => {
              console.debug('MainNews.setNextTimeout.rotate ad logged to server (direct)', JSON.stringify(layout.main[next]));
            });
          }

          console.debug('MainNews.setNextTimeout.rotate ad logged to server', JSON.stringify(layout.main[next]));
        }

        // Track all media (use for views counter):
        if (isTracking) {
          const media = layout.main[next];
          // В зависимости от версии киоска отправляем аналитику
          // @ts-ignore
          if (window.mediaShownInElectron && board.version > 1) {
            console.info('MainNews.setNextTimeout.rotate mediaShownInElectron start (electron)');
            // @ts-ignore
            window.mediaShownInElectron({
              mediaId: media.id,
              mediaType: media.hasOwnProperty('adId') ? 'AD' : 'NEWS',
              adId: media.adId,
              boardId: Number(boardId),
              utcTimestamp: new Date(new Date().toUTCString()).getTime()
            });
            console.debug('MainNews.setNextTimeout.rotate media logged to server (electron)', JSON.stringify(layout.main[next]));
          } else {
            console.debug('MainNews.setNextTimeout.rotate unsupported board.version or media shown not in electron app', JSON.stringify(layout.main[next]));
          }

          console.debug('MainNews.setNextTimeout.rotate media tracking ends', JSON.stringify(layout.main[next]));
        }

        console.info("MainNews.setNextTimeout.rotate exit")
      }

      const timeout = fireAfter || parseInt(seconds || '15') * 1000;

      console.info("MainNews.setNextTimeout set timeout =", timeout)
      clearTimeout(rotateTimeout.current);
      setNextTimeoutAt(Date.now() + timeout);
      rotateTimeout.current = setTimeout(rotate, timeout);
      console.info("MainNews.setNextTimeout exit")
    }
  }

  useEffect(() => {
    console.info("MainNews effect uuid =", JSON.stringify(uuid))
    console.debug("MainNews effect uuid layout =", JSON.stringify(layout))
    console.info("MainNews effect uuid set active main")
    setActiveMain(0);
    console.info("MainNews effect uuid set active feed")
    setActiveFeed(0);
    console.info("MainNews effect uuid done")
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid])

  useEffect(() => {
    console.info("MainNews effect activeMain =", activeMain)
    if (activeMain !== undefined) {
      console.info("MainNews effect activeMain is not undefined")
      setNextTimeout(activeMain, activeFeed || 0, fullView, picInPic);
      console.info("MainNews effect activeMain setNextTimeout done")
      return () => {
        getLayout();
        console.info("MainNews effect activeMain cleanup get layout done")
        console.debug("MainNews effect activeMain cleanup get layout after =", JSON.stringify(layout))
      }
    }else {
      console.info("MainNews effect activeMain undefined")
    }
    console.info("MainNews effect activeMain")
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMain])

  useEffect(() => {
    console.info("MainNews effect picInPic =", picInPic, " activeMain =", activeMain)
    if (picInPic && activeMain !== undefined) {
      setNextTimeout(activeMain, activeFeed || 0, fullView, picInPic);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [picInPic])

  useEffect(() => {
    console.info("MainNews effect fullView =", fullView, " activeMain =", activeMain)
    if (activeMain !== undefined) {
      const fireAfter = nextTimeoutAt - Date.now();
      if (fullView) {
        setNextTimeout(activeMain, activeFeed || 0, fullView, picInPic, fireAfter);
      } else {
        setPicInPic(false);
        setNextTimeout(activeMain, activeFeed || 0, fullView, false, fireAfter);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullView])


  const hasMainContent = activeMain !== undefined && layout.main;

  const getFeedClass = (feed: IFeedTile[]) => {
    let i = feed.findIndex((f) => f.format === AdFormat.FEED_2X2);
    if (i !== -1) {
      const i1x2 = feed.findIndex((f) => f.format === AdFormat.FEED_1X2);
      // 2x2 + 1x2
      if (i1x2 !== -1) {
        return 'FEED_2X2-1X2-' + i;
      }
      return 'FEED_2X2-' + i;
    }
    i = feed.findIndex((f) => f.format === AdFormat.FEED_1X2);
    if (i !== -1) {
      // apply 1x2 template by count
      const filteredLength = feed.filter((f) => f.format === AdFormat.FEED_1X2).length;
      return 'FEED_1X2-' + filteredLength;
    }

    return feed[0].format;
  }

  // @ts-ignore
  const activeMainTile = hasMainContent && layout.main[activeMain];

  return (
    <div className={classes.root}>
      {board?.version === 1 && (
          <div className={classes.title}>
            {activeMainTile && !picInPic && activeMainTile.adId && activeMainTile.format === AdFormat.FULLSCREEN && activeMainTile.title
                ? activeMainTile.title : 'Главное'}
          </div>
      )}

      <div className={classes.wrapper}>
        {hasMainContent
          ? layout.main.map((item, i) => (
            <MainView
              item={item}
              boardVersion={board.version}
              setFullViewing={setFullView}
              full={fullView}
              // @ts-ignore
              style={getTileStyle(i, activeMain, !!item.adId, picInPic, item.format === AdFormat.HALFSCREEN)}
              pip={picInPic && activeMain === i}
              active={i === activeMain}
              key={item.id}
            />
          )) : <>Нет данных</>}
      </div>
      {board?.version === 1 && (
          <>
            <div className={clsx(classes.title, 'full-list')}>Лента</div>
            <div className={clsx(classes.list, activeFeed !== undefined && layout.feed ? getFeedClass(layout.feed[activeFeed]) : undefined)}>
              {activeFeed !== undefined && layout.feed
                  ? layout.feed[activeFeed].map((i, index) => (
                      <NewsItem item={i} key={index} style={{ gridArea: `${i.format.toLowerCase()}-${index}` }} />
                  )) : <>Нет данных</>}
            </div>
          </>
      )}
    </div>
  );
}
