import { IPaletteCommon } from '../type';

const palette: IPaletteCommon = {
  mark: {
    red: '#AB210A',
    orange: '#E2930E',
    green: '#448302',
  }
}

export default palette;
