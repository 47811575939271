import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';

import { useBoardsList, IBoard } from '../../api/boards';
import { ITheme } from '../../theme/type';

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    width: '100%',
    margin: '10px auto',
    padding: '10px',
    'max-width': '500px',
    'box-sizing': 'border-box'
  },
  search: {
    borderRadius: 8,
    height: 60,
    lineHeight: '24px',
    fontSize: 24,
    padding: '10px 10px',
    marginBottom: 50,
    width: '100%',
    'box-sizing': 'border-box'
  },
  item: {
    color: theme.palette.text.blue,
    fontSize: 18,
    lineHeight: '24px',
    'box-sizing': 'border-box'
  },
  disabled: {
    color: theme.palette.text.subtitle
  },
  loading: {
    fontSize: 18,
    textAlign: 'center',
    'box-sizing': 'border-box'
  }
}));

export default function Boards() {
  const classes = useStyles();
  const [{ data: boards }, loading, load] = useBoardsList();
  const [searchStr, setSearch] = useState('');
  const [list, setList] = useState<IBoard[]>([]);

  useEffect(() => {
    load();
  }, [])

  useEffect(() => {
    setList(boards);
  }, [boards])

  useEffect(() => {
    let res = boards;
    if (searchStr && searchStr.length) {
      res = boards.filter((b: IBoard) => b.id.includes(searchStr) || b.name.includes(searchStr) || b.title.includes(searchStr));
    }
    setList(res);
  }, [searchStr])

  return (
    <div className={classes.root}>
      {loading
        ? <div className={classes.loading}>Loading...</div>
        : (
          <>
            <input
              className={classes.search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Название, ID или остановка"
            />
            {list.length === 0 && <span className={classes.disabled}>Нет данных, отвечающих запросу</span>}
            <div>
              {list.map((b: IBoard) => (
                <div key={b.id}>
                  {b.active
                    ? (
                      <>
                        <a href={`/${b.id}`} className={classes.item}>{b.name}</a>
                        <br/>
                        <a href={`/${b.id}?theme=dark`} className={classes.item}>Темная тема</a>
                      </>
                    ) : <span className={classes.disabled}>{b.name} ({b.title})</span>
                  }
                  <br/>
                  {b.kiosk.controllerIp &&
                  (<div>
                    <a href={`https://controller.citytv.tech/${b.kiosk.controllerIp}/ui`} className={classes.item}>Controller UI</a>
                    {' '}
                    [<a href={`https://controller.citytv.tech/${b.kiosk.controllerIp}`} className={classes.item}>Editor</a>]
                  </div>) }
                  <br/>
                </div>
              ))}
            </div>
          </>
        )
      }
    </div>
  );
}
