import { commonTypo } from '../common';
import palette from './palette';

export default {
  ...commonTypo,
  heading: {
    ...commonTypo.heading,
    color: palette.text.default
  }
}
