import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';

import Weather from '../../../components/Weather';
import Transport from './components/Transport';
import { ITheme } from '../../../theme/type';
import clsx from 'clsx';
import { IBoard } from '../../../api/boards';
import { themeIsDark } from '../../../App';

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    width: '2160px',
    overflow: 'hidden',
    '&.old-version': {
      background: theme.palette.background.alternative,
      width: '100%',
    },
    height: 626,
    padding: '40px 0 50px',
    '&:not(.old-version)': {
      position: 'absolute',
      zIndex: 99999,
    }
  },
  head: {
    '&:not(.old-version)': {
      padding: '0 26px',
      margin: '0 30px',
      backdropFilter: 'blur(4px)',
      backgroundColor: () => !themeIsDark ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.5)',
      borderRadius: '22px',
    },
    '&.old-version': {
      padding: '0 70px',
    },
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontFamily: '"SF Pro Display", sans-serif',
    fontWeight: 700,
    fontSize: 86,
    lineHeight: '103px',
  },
  weatherTime: {
    display: 'flex',
    alignItems: 'center',
  },
  time: {
    fontWeight: 500,
    fontSize: 76,
    lineHeight: '91px',
    letterSpacing: '-4.05px',
  },
}))

interface IProps {
  title: string;
  boardId: number;
  board: IBoard;
}

export default function TopPanel({ title, boardId, board }: IProps) {
  const classes = useStyles();
  const [time, setTime] = useState(new Date());

  function timer() {
    setTime(new Date());
    setTimeout(timer, 10000);
  }

  useEffect(() => {
    timer();
  }, [])

  return (
    <div className={clsx(classes.root, board.version === 1 ? 'old-version' : '')}>
      <div className={clsx(classes.head, board.version === 1 ? 'old-version' : '')}>
        <div className={classes.title}>{title}</div>
        <div className={classes.weatherTime}>
          <Weather boardId={boardId} />
          <div className={classes.time}>
            {time.getHours()}:{time.getMinutes() < 10 ? `0${time.getMinutes()}` : time.getMinutes()}
          </div>
        </div>
      </div>
      <Transport board={board} boardId={boardId} />
    </div>
  );
}
