import makeStyles from '@material-ui/styles/makeStyles';

import { ITheme } from './type';

interface IProps {
  fhd?: boolean;
}

const useGlobalStyles = makeStyles((theme: ITheme) => ({
  root: {
    background: theme.palette.background.default,
    width: ({ fhd }: IProps) => fhd ? 1080 : 2160,
    minHeight: '100vh',
    height: ({ fhd }: IProps) => fhd ? 1920 : 3840,
    overflow: 'hidden',
    color: theme.palette.text.default
  },
  loader: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

export default useGlobalStyles;
