import React, { useEffect } from "react";
import makeStyles from "@material-ui/styles/makeStyles/makeStyles";

import { ITheme } from "../../../../theme/type";
import axios from 'axios';

const useStyles = makeStyles((theme: ITheme) => ({
  background: {
    width: '100%',
    height: ({ isPip, isFullScreen, mode }: any) => {
      const fullHeight = mode === 'old' ? 3050 : 3840;
      return isPip ? (3050 / 2 * 0.9) : isFullScreen ? fullHeight : 1680;
    },
    'background-color': theme.palette.background.default,
  },
  video: {
    width: '100%',
    height: ({ isPip, isFullScreen, mode }: any) => {
      const fullHeight = mode === 'old' ? 3050 : 3840;
      return isPip ? (3050 / 2 * 0.9) : isFullScreen ? fullHeight : 1680
    },
  },
}))

interface IProps {
  url: string;
  isFullScreen: boolean;
  isPip: boolean;
  isActive: boolean;
  mode: 'fullscreen' | 'old';
  setLoaded(arg0: boolean): void;
}

export default function Video({ url, setLoaded, isFullScreen, isPip, isActive, mode }: IProps) {
  const video = React.useRef<HTMLVideoElement>(null);

  const [isVideoLoaded, setIsVideoLoaded] = React.useState(false);

  const onVideoLoaded = (videoUrl: string) => {
    if (video && video.current) video.current.src = videoUrl;
    setIsVideoLoaded(true);
    setLoaded(true);
  };
  const classes = useStyles({ isFullScreen, isPip, mode });

  // @ts-ignore
  useEffect(() => {
    if (isVideoLoaded && video && video.current) {
      if (isActive) {
        video.current.currentTime = 0;
        video.current.play();
      } else {
        video.current.currentTime = 0;
        video.current.pause();
      }
    }
  }, [isActive, video, isVideoLoaded]);

  useEffect(() => {
    let videoUrl = '';

    async function load() {
      const HEAD_TIMEOUT = 5 * 1000; // 5sec
      const BLOB_TIMEOUT = HEAD_TIMEOUT * 6; // 30sec
      const MAX_BLOB_CHUNK = 512 * 1024; // 512kb
      const head = await axios.head(url, { responseType: 'blob', timeout: HEAD_TIMEOUT })
      if (head.headers['accept-ranges'] === 'bytes') {
        const size = Number(head.headers['content-length']);
        let chunks = Math.abs(size / MAX_BLOB_CHUNK);
        if (size > chunks * MAX_BLOB_CHUNK) chunks += 1;
        const blobs = [];
        for (let i = 0; i < chunks; i++) {
          let fetchTo = (i + 1) * MAX_BLOB_CHUNK;
          if (fetchTo > size) fetchTo = size;
          const resp = await axios.get(url, { headers: {
              'Range': `bytes=${i * MAX_BLOB_CHUNK}-${fetchTo-1}`,
              'Accept': '*/*'
            }, responseType: 'blob', timeout: BLOB_TIMEOUT })
          blobs.push(resp.data);
        }
        videoUrl = URL.createObjectURL(new Blob(blobs));
        onVideoLoaded(videoUrl);
      } else {
        const resp = await axios.head(url, { responseType: 'blob', timeout: BLOB_TIMEOUT })
        videoUrl = URL.createObjectURL(resp.data);
        onVideoLoaded(videoUrl);
      }
    }

    load();

    return function cleanup() {
      URL.revokeObjectURL(videoUrl);
    }
  }, [ url ]);

  return (
    <div className={classes.background}
         style={{ display: isVideoLoaded ? 'inherit' : 'none' }}>
      <video
        ref={ video }
        className={classes.background}
        autoPlay
        playsInline
        muted
        disablePictureInPicture
      />
    </div>
  );
}
