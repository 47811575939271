import Api, { useFetchApi } from './index';

export interface INewsAdsItem {
  boards: string[];
  createdAt: string;
  mainImage: string;
  mainVideo?: string;
  mainText?: string;
  name: string;
  previewImage: string;
  slug: string;
  subtitle?: string;
  timestamp?: string;
  isArchived: boolean;
  isDraft: boolean;
  isAds?: boolean;
  id: string;
  screenTime?: number;
}

type AdShowParams = {
  adId: number,
  boardId: number
}

export function useNewsList() {
  return useFetchApi(
    async (boardId: string) => Api.get(`/news/${boardId}`),
    { data: { news: [], ads: [] } },
    true,
    'useNewsList'
  )
}

export function useCheckUpdates() {
  return useFetchApi(
    async () => Api.get('/check-updates'),
    { lastUpdate: undefined },
    false,
    'useCheckUpdates'
  )
}

export function adShown({ adId, boardId }: AdShowParams) {
  return Api.post('/adlog/show', { adId, boardId });
}
