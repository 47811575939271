import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useTheme } from '@material-ui/styles';
import Loader from 'react-loader-spinner';

import TopPanel from './TopPanel';
import MainNews from './MainNews';
import useGlobalStyles from '../../../theme/useGlobalStyles';
import { useBoard, useBoardInfo, useNewLayout } from '../../../api/boards';
import { ITheme } from '../../../theme/type';
import axiosRetry from 'axios-retry';
import axios from 'axios';
// @ts-ignore
import { ConcurrencyManager } from 'axios-concurrency';
import { Layout } from './Layout';

export default function Main() {
  const globalClasses = useGlobalStyles({});
  const theme: ITheme = useTheme();
  const { boardId } = useParams();

  const [uuid, setUuid] = useState('');

  const uuidRef = useRef(uuid)

  const [{ data: layout }, loading, load] = useBoard();
  const [{ data: newLayout }, loadingNewLayout, loadNewLayout] = useNewLayout();
  const [{ data: board }, loadingBoardInfo, loadBoardInfo] = useBoardInfo();

  const currentLayoutUuid = layout?.uuid ?? ''

  useEffect(() => {
    loadBoardInfo(boardId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boardId])


  useEffect(() => {
    if (!board.version) {
      return
    }

    ConcurrencyManager(axios, 1);
    axiosRetry(axios, { retries: 1000, shouldResetTimeout: true, retryDelay: () => 5000, retryCondition: (error) => !!error });

    if (board.version === 1) {
      console.log('board version is 1, loading old layout with boardId = ', boardId, ', uuid = ,', uuidRef.current)
      load({ id: parseInt(boardId), uuid: uuidRef.current });
    } else {
      console.log('board version is 2, loading new layout with boardId = ', boardId)
      loadNewLayout(+boardId)
    }

     /* debug data */
     console.info("index load layout =", boardId)
     console.debug("index layout =", JSON.stringify(layout))
     console.debug("index boards =", JSON.stringify(board))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [board.version, boardId])


  useEffect(() => {
    if (currentLayoutUuid) {
      console.info("index effect uuid =", uuid, currentLayoutUuid)
      setUuid(currentLayoutUuid);
      console.info("index effect uuid set")
    }
  }, [layout, currentLayoutUuid, uuid])

  const isLoading = board.version === 1 ? (loading && !layout) : (loadingNewLayout && !newLayout)

  if ((loadingBoardInfo && !board) || isLoading) {
    return (
      <div className={globalClasses.root}>
        <div className={globalClasses.loader}>
          <Loader
            type="Grid"
            color={theme.palette.text.default}
            height={200}
            width={200}
            />
        </div>
      </div>
    )
  }

  return board.version === 1 ? (
    <>
      <TopPanel title={board?.title || board?.name} board={board} boardId={parseInt(boardId)}/>
      <MainNews
        uuid={uuid}
        board={board}
        getLayout={() => {
            console.info("index get layout board =", boardId)
            load({ id: +boardId })
            console.debug("index get layout layout =", JSON.stringify(layout))
          }}
        layout={layout.layout}
      />
    </>
  ) : (
    <>
      <TopPanel title={board?.title || board?.name} board={board} boardId={parseInt(boardId)}/>
      <Layout
        boardId={board.id}
        boardVersion={board.version}
        layout={newLayout}
        getLayout={() => {
          console.info("index get layout board =", boardId)
          loadNewLayout(boardId)
          console.debug("index get layout layout =", JSON.stringify(layout))
        }}
      />
    </>

  )
}
