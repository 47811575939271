import { IPaletteTheme } from '../type';
import { commonPalette } from '../common';

const palette: IPaletteTheme = {
  ...commonPalette,
  text: {
    default: '#fff',
    subtitle: 'rgba(256,256,256,0.38)',
    content: 'rgba(256,256,256,0.6)',
    blue: '#007AFF'
  },
  background: {
    default: '#000',
    alternative: '#1F1F1F'
  },
  arriveTimeBg: {
    red: '#AB210A',
    orange: '#E2930E',
    green: '#448302'
  },
  arriveTimeText: {
    red: '#fff',
    orange: '#fff',
    green: '#fff',
  }
}

export default palette;
