import Api, { useFetchApi } from './index';

export interface ITransport {
  arrivalTimeKaliningrad: string;
  arrivalTimeLeftMs: number;
  directionStart: string;
  directionStop: string;
  vehicleRouteNumber: string;
  vehicleTypeDescription: string;
  vehicleTypeGlonassNotation: string;
  vehicleTypeId: number;
}

export interface IRoute {
  arriveAt: number;
  number: string;
  type: string;
  destinationRu: string;
  destinationEn: string;
}

export function useTransport() {
  return useFetchApi(
    async (id: number) => Api.post('/board/public/schedule', { id }),
    { data: [] },
    true
  )
}
