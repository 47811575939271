import React from 'react';

interface IProps {
  type: 'snow' | 'rain' | 'clouds' | 'clear' | 'thunderstorm' | 'drizzle';
}

export default function WeatherIcon({ type }: IProps) {
  switch (type) {
    case 'snow': {
      return (
        <svg width={73} height={67} viewBox="0 0 73 67" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M60.8699 50.4174C67.4642 47.5293 71.1325 40.4155 69.6613 33.3684C68.1901 26.3213 61.9819 21.2693 54.7829 21.2609H50.9482C48.2991 11.0027 39.3205 3.63505 28.7426 3.0394C18.1646 2.44375 8.41568 8.75683 4.63173 18.6528C0.847782 28.5487 3.89751 39.7557 12.1742 46.3696" stroke="black" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M24.3483 45.6087H24.3788" stroke="black" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M24.3483 57.7826H24.3788" stroke="black" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M36.5221 51.6957H36.5526" stroke="black" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M36.5221 63.8696H36.5526" stroke="black" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M48.696 45.6087H48.7264" stroke="black" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M48.696 57.7826H48.7264" stroke="black" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      )
    }
    case 'rain': {
      return (
        <svg width={73} height={73} viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M48.6956 39.5218V63.8696" stroke="black" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M24.3479 39.5218V63.8696" stroke="black" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M36.5217 45.6087V69.9565" stroke="black" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M60.8699 50.4174C67.4642 47.5293 71.1325 40.4155 69.6613 33.3684C68.1901 26.3213 61.9819 21.2693 54.7829 21.2609H50.9482C48.2991 11.0027 39.3205 3.63505 28.7426 3.0394C18.1646 2.44375 8.41568 8.75683 4.63173 18.6528C0.847782 28.5487 3.89751 39.7557 12.1742 46.3696" stroke="black" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      )
    }
    case 'clouds': {
      return (
        <svg width={73} height={55} viewBox="0 0 73 55" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M54.7828 21.2609H50.948C47.7842 9.00752 35.7608 1.22081 23.2853 3.34566C10.8097 5.47051 2.04194 16.7984 3.11285 29.4082C4.18377 42.018 14.7363 51.7051 27.3915 51.6957H54.7828C63.1871 51.6957 70.0002 44.8826 70.0002 36.4783C70.0002 28.0739 63.1871 21.2609 54.7828 21.2609Z" stroke="black" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      )
    }
    case 'clear': {
      return (
        <svg width={76} height={76} viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="37.9999" cy="38" r="15.9091" stroke="black" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M38.0001 3V9.36364" stroke="black" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M38.0001 66.6364V73" stroke="black" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M13.2451 13.2455L17.7633 17.7636" stroke="black" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M58.2363 58.2364L62.7545 62.7545" stroke="black" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M3 38H9.36364" stroke="black" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M66.6367 38H73.0004" stroke="black" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M13.2451 62.7545L17.7633 58.2364" stroke="black" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M58.2363 17.7636L62.7545 13.2455" stroke="black" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      )
    }
    case 'thunderstorm': {
      return (
        <svg width={73} height={73} viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M57.8259 51.3913C65.4876 49.8356 70.7238 42.7273 69.9381 34.9489C69.1524 27.1705 62.6004 21.253 54.7825 21.2609H50.9477C48.1713 10.5119 38.4753 3.00206 27.3735 3.00206C16.2718 3.00206 6.57577 10.5119 3.79938 21.2609C1.023 32.0099 5.86935 43.2758 15.5825 48.6522" stroke="black" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M39.5655 33.4348L27.3916 51.6957H45.6525L33.4786 69.9565" stroke="black" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      )
    }
    case 'drizzle': {
      return (
        <svg width={73} height={73} viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M24.3479 57.7826V63.8696" stroke="black" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M24.3479 39.5218V45.6087" stroke="black" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M48.6956 57.7826V63.8696" stroke="black" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M48.6956 39.5218V45.6087" stroke="black" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M36.5217 63.8696V69.9565" stroke="black" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M36.5217 45.6087V51.6957" stroke="black" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M60.8699 50.4174C67.4642 47.5293 71.1325 40.4155 69.6613 33.3684C68.1901 26.3213 61.9819 21.2693 54.7829 21.2609H50.9482C48.2991 11.0027 39.3205 3.63505 28.7426 3.0394C18.1646 2.44375 8.41568 8.75683 4.63173 18.6528C0.847782 28.5487 3.89751 39.7557 12.1742 46.3696" stroke="black" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      )
    }
    default:
      return null;
  }
}
