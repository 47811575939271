import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import Boards from './views/Boards';
import Main from './views/4k/Main';

export const useQuery = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Routes() {
  return (
    <Switch>
      <Route path="/" component={Boards} exact />
      <Route path="/:boardId" component={Main} exact />
    </Switch>
  )
}
