import Api, { useFetchApi } from './index';

interface IWeatherData {
  id: number;
  main: string;
  description: string;
  icon: string;
}

export interface IWeather {
  weather: IWeatherData[];
  main: {
    temp: number;
    feels_like: number;
    temp_min: number;
    temp_max: number;
    pressure: number;
    humidity: number;
  };
  visibility: number;
  wind: {
    speed: number;
    deg: number;
  };
  clouds: { all: number };
  id: number;
  name: string;
}

export function useWeather() {
  return useFetchApi(
    async (id: number) => Api.post('/board/public/weather', { id }),
    { data: {} },
    false,
    'useWeather'
  )
}
