import { ITypo } from '../type';

const typography: ITypo = {
  heading: {
    fontFamily: '"SF Compact Display", sans-serif',
    fontWeight: 700,
    fontSize: window.location.pathname.includes('/fhd/') ? 29 : 58,
    lineHeight: window.location.pathname.includes('/fhd/') ? '34px' : '69px'
  }
}

export default typography;
