import React, { useEffect } from 'react';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';

import Icons from './Icons';
import { ITheme } from '../../theme/type';
import { useWeather } from '../../api/weather';

interface IProps {
  fhd?: boolean;
}

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'SF Compact Display',
    fontSize: 81,
    lineHeight: '97px',
    marginRight: 120
  },
  icon: {
    marginRight: 30,
    '& svg': {

    },
    '& svg path, & svg circle': {
      stroke: theme.palette.text.default
    }
  }
}))

interface IProps {
  boardId: number;
}

export default function Weather({ boardId }: IProps) {
  const classes = useStyles();
  const [{ data: weather }, , load] = useWeather();

  function get() {
    load(boardId);
    setTimeout(get, 60000 * 5);
  }

  useEffect(() => {
    get();
  }, [])

  if (!weather.id) {
    return null;
  }

  return (
    <div className={classes.root}>
      <div className={classes.icon}>
        <Icons type={weather.weather[0].main.toLowerCase()} />
      </div>
      <span>{Math.round(weather.main.temp)}&deg;</span>
    </div>
  );
}
