import axios from 'axios';
import { useState } from 'react';


const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3080/api';

const Api = axios.create({
  baseURL: apiUrl,
});

export default Api;

const saveToLs = (cacheKey: string, data: any) => {
  localStorage.setItem(cacheKey, JSON.stringify(data));
}

const getFromLs = (cacheKey: string) => {
  const data = localStorage.getItem(cacheKey);

  try {
    return data ? JSON.parse(data) : {};
  } catch (e) {
    return {};
  }
}


export function useFetchApi(remote: any, initialData: any, fetchOnLoad = false, cacheKey?: string) {
  const [state, setState] = useState({ ...initialData, error: null });
  const [loading, setLoading] = useState(fetchOnLoad);

  const load = async (...args: any) => {
    setLoading(true);
    let res: any = {};
    try {
      res = await remote(...args);
    } catch (e) {
      setState({
          ...state,
          error: e.toString(),
          ...(cacheKey ? getFromLs(cacheKey) : {})
      });
    }

    if (res.data) {
      if (res.data.error) {
        setState({ ...state, error: res.data.error });
      } else {
        setState({
          ...state,
          ...res.data,
          ...(!loading && cacheKey ? getFromLs(cacheKey) : {}),
          error: null,
        });

        if (cacheKey) {
          saveToLs(cacheKey, res.data);
        }
      }
    }

    setLoading(false);
  };

  return [state, loading, load];
}
