import palette from './palette';
import typography from './typography';

import { ITheme } from '../type';

const DarkTheme: ITheme = {
  name: 'dark',
  palette,
  typography
}

export default DarkTheme;
