import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';

import { ITheme } from '../../../../theme/type';
import { IFeedTile, FeedFormat, AdFormat } from '../../../../api/boards';

const width = 700;
const height = 600;

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    background: ({ image }: any) => `url("${image}") no-repeat center / cover ${theme.palette.background.default}`,
    opacity: 0.5,
    transition: 'opacity, background 1000ms easy 0s',
    borderRadius: 10
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: '40px 30px',
    color: '#fff',
    fontSize: 26,
    lineHeight: '34px',
    letterSpacing: '-0.72px',
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 70%, rgba(0, 0, 0, 0.8) 100%)',
    borderRadius: 10
  },
  title: {
    lineHeight: '38px',
    fontWeight: 700
  },
  subtitle: {
    color: 'rgba(256,256,256,0.6)'
  }
}));

interface IProps {
  item: IFeedTile;
  style: { gridArea: string };
}

export default function NewsItem({ item, style }: IProps) {
  const classes = useStyles({ image: item.image });
  const itemRef = useRef(null);

  useEffect(() => {
    if (itemRef.current) {
      // @ts-ignore
      itemRef.current.style.opacity = 1;
    }
  }, [item, itemRef])

  return (
    <div className={clsx(classes.root, item.format)} ref={itemRef} style={style}>
      {item.title && (
        <div className={classes.content}>
          <div className={classes.title}>{item.title}</div>
        </div>
      )}
    </div>
  )
}
