import palette from './palette';
import typography from './typography';

import { ITheme } from '../type';

const LightTheme: ITheme = {
  name: 'light',
  palette,
  typography,
}

export default LightTheme;
