import React, { useEffect } from 'react';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';
import Marquee from 'react-marquee-slider';

import TransportItem from './TransportItem';
import { useTransport, ITransport, IRoute } from '../../../../api/transport';
import { ITheme } from '../../../../theme/type';
import { IBoard } from '../../../../api/boards';
import clsx from 'clsx';

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    marginTop: 36,
    height: 260,
    '&.old-version': {
      height: 396,
    },
    position: 'relative'
  },
  content: {
    display: 'flex',
  },
  empty: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 56,
    color: theme.palette.text.default,
    height: '100%'
  }
}));

interface IProps {
  boardId: number;
  board: IBoard;
}

export default function Transport({ boardId, board }: IProps) {
  const classes = useStyles();
  const [{ data: routes }, loading, load] = useTransport();
  function fetch() {
    load(boardId);
    setTimeout(fetch, 60000);
  }

  useEffect(() => {
    fetch();
  }, [])

  return (
    <div className={clsx(classes.root, board.version === 1 ? 'old-version' : '')}>
      {routes.length
        ? (
          <>
            {routes.length > 2
              ? (
                <Marquee velocity={20}>
                  {routes.map((item: IRoute, i: number) => (
                    <TransportItem
                      item={item}
                      key={i}
                      mode={board.version === 1 ? 'old' : 'fit'}
                    />
                  ))}
                </Marquee>
              ) : (
                <>
                  {routes.map((item: IRoute, i: number) => (
                    <TransportItem
                      item={item}
                      key={i}
                      mode={board.version === 1 ? 'old' : 'fit'}
                    />
                  ))}
                </>
              )}
          </>
        ) : (
          <div className={classes.empty}>Информация о прибытии транспорта временно недоступна</div>
        )}
    </div>
  );
}
